
.react-calendar { 
  width: 62rem !important;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.2em !important;
 }
 .react-calendar__navigation button {
  color: #8e0000;

  background: none;
  margin-top: 8px;
  font-size: 2em !important;
  font-weight: bold;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 .react-calendar__month-view__days__day {
  border: 1px solid #ccc; 
 }
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #8e0000;
  border-radius: 6px;
  font-weight: bold;
  font-size: 1rem !important;
 }
 .react-calendar__tile--now {
  background: #f8f8fa;
  font-weight: bold !important;
  color: #8e0000;
  font-size: 1rem !important;

 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #f8f8fa;
  border-radius: 6px;
  font-weight: bold;
  color: #8e0000;
  font-size: 1rem !important;

 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
  font-size: 1rem !important;

 }

 .react-calendar__tile--hasActive {
  background: transparent;
}

 .react-calendar__tile--active {
  background: #f8f8fa;
  border-radius: 6px;
  font-weight: bold;
  color: white;
  font-size: 1rem !important;
 }
 .react-calendar__tile > :first-child {
  color: #8e0000; 
  font-weight: bold;
}

 .react-calendar__tile {
  
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  min-height: 1rem !important; 
  padding: 10px;
  border: 1px solid #ccc;
  overflow: hidden; 
  text-overflow: ellipsis;
  font-size: 1rem;
  font-size: 1rem !important;
  border: 1px solid #ccc !important;
  padding: 20px !important;
}

  .react-calendar__month-view__days__day--weekend {
 color: black !important;
} 

 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
  font-size: 1rem !important;
 }

 