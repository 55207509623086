@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide the default clear button in input fields */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: white;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #A30000;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #8E0000;
}

.scrollbar1::-webkit-scrollbar {
  width: 16px;
}

.scrollbar1::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #A30000;
}

.scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #8E0000;
}

.custom-scrollbar-table {
  padding-bottom: 2px !important;
}

.custom-scrollbar-table::-webkit-scrollbar {
  width: 10px !important; 
}

.custom-scrollbar-table::-webkit-scrollbar-track {
  border-radius: 8px !important;
  background: white !important;
}

.custom-scrollbar-table::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background: #A30000 !important;
}

.custom-scrollbar-table::-webkit-scrollbar-thumb:hover {
  background: #8E0000 !important;
}

